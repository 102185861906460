body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bundle {
  background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
  radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
  transition: 0.18s ease-out;
  cursor: pointer;
}

.bundle:hover {
  transform: scale(1.3) rotate(5deg);
  box-shadow:
  0 0 30px 15px #fff,  /* inner white */
  0 0 50px 30px rgba(255, 251, 0, 0.588), /* middle magenta */
  0 0 70px 45px #fdb9317b; /* outer cyan */
} 

iframe {
  border: 0 !important;
}